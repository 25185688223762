var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Search', {
    attrs: {
      "default-search-data": _vm.searchData
    }
  }), _c('SearchResultBanner', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isVisibleBannerSearch,
      expression: "isVisibleBannerSearch"
    }],
    on: {
      "closeBanner": _vm.handleCardBannerSearchClick
    }
  }), ['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? _c('b-card', {
    staticClass: "rounded-lg mb-50 mb-lg-1 mt-50",
    attrs: {
      "header-bg-variant": "warning",
      "header-class": "p-0 rounded_header",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "w-100 mx-0"
        }, [_c('b-col', {
          staticClass: "d-flex align-items-center text-white cursor-pointer border-right px-25 px-sm-1",
          attrs: {
            "cols": "6"
          },
          on: {
            "click": _vm.toggleVisibleCheapestHandle
          }
        }, [_c('span', {
          class: "fw-700 mr-1 ".concat(_vm.isMobileView ? 'font-small-4' : '')
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.neighboringDays')) + " ")]), _c('div', {
          staticClass: "p-25"
        }, [_c('IconSvg', {
          staticClass: "py-25 rotate_class text-white",
          style: {
            transform: _vm.rotateStyleCheapest,
            width: '18px'
          },
          attrs: {
            "src": require('@icons/triangle-down.svg'),
            "blank-color": "#ccc"
          }
        })], 1)]), _c('b-col', {
          staticClass: "border-left d-flex align-items-center justify-content-end",
          attrs: {
            "cols": "6"
          },
          on: {
            "click": _vm.handleToggleFilter
          }
        }, [_c('span', {
          class: "fw-700 text-white mr-1 ".concat(_vm.isMobileView ? 'font-small-4' : '')
        }, [_vm._v(" Bộ lọc ")]), _c('div', {
          staticClass: "p-25"
        }, [_c('IconSvg', {
          staticClass: "fw-600 font-medium-2",
          attrs: {
            "src": require('@icons/filter-white.svg'),
            "blank-color": "#ccc"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }], null, false, 172003416)
  }, [_c('b-collapse', {
    model: {
      value: _vm.isVisibleCheapestFare,
      callback: function callback($$v) {
        _vm.isVisibleCheapestFare = $$v;
      },
      expression: "isVisibleCheapestFare"
    }
  }, [_c('CheapestFare', {
    staticClass: "mb-50"
  })], 1)], 1) : _vm._e(), ['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? _c('SelectedTripCard') : _vm._e(), ['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? _c('FastFilterByTypeSelect') : _vm._e(), _c('b-row', {
    class: _vm.isRoleF3 ? 'mt-1' : ''
  }, [_c('b-col', {
    staticClass: "pl-50 pr-50 pr-lg-0",
    attrs: {
      "sm": "12",
      "lg": "8",
      "xl": "9"
    }
  }, [['lg', 'xl'].includes(_vm.appBreakPoint) && _vm.isVisibleCheapestFare ? _c('CheapestFare', {
    staticClass: "mb-0",
    on: {
      "click": _vm.toggleVisibleCheapestHandle
    }
  }) : _vm._e(), _c('Results', {
    class: _vm.isVisibleCheapestFare ? '' : 'mt-50'
  })], 1), ['lg', 'xl'].includes(_vm.appBreakPoint) ? _c('b-col', {
    staticClass: "pr-50",
    attrs: {
      "lg": "4",
      "xl": "3"
    }
  }, [_c('vue-perfect-scrollbar', {
    staticClass: "ps-customizer-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('div', {
    staticClass: "right-side-sticky"
  }, [_c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('b-card', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    class: "flex-1 my-50 mx-25 border-info ".concat(_vm.isCardCheapestFareDisabled ? 'cursor-not-allowed' : 'cursor-pointer'),
    attrs: {
      "header-bg-variant": "light-info",
      "header-class": "py-25 px-25",
      "no-body": "",
      "disabled": _vm.isCardCheapestFareDisabled
    },
    on: {
      "click": _vm.handleCardCheapestFareClick
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [!_vm.isCardCheapestFareDisabled ? _c('div', {
          staticClass: "w-100 d-flex align-items-center justify-content-around flex-nowrap"
        }, [_c('b-img', {
          class: {
            'rotate-180': _vm.isVisibleCheapestFare
          },
          attrs: {
            "src": require('@icons/expland-left-double.svg'),
            "blank-color": "#ccc",
            "width": "21"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm.$t("flight.".concat(_vm.isVisibleCheapestFare ? 'hideCheapestFareSwiper' : 'showCheapestFareSwiper'))))]), _c('b-img', {
          attrs: {
            "src": require('@icons/date-info.svg'),
            "blank-color": "#ccc",
            "width": "21"
          }
        })], 1) : _c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "variant": "info"
          }
        }), _c('span', {
          staticClass: "text-info ml-25"
        }, [_vm._v("Đang thực hiện ...")])], 1)];
      },
      proxy: true
    }], null, false, 3789146042)
  }), _vm.haveBannerSearch ? _c('b-card', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    class: "my-50 mx-25 border-warning ".concat(_vm.isCardBannerSearchDisabled ? 'cursor-not-allowed' : 'cursor-pointer'),
    attrs: {
      "header-bg-variant": "light-warning",
      "header-class": "py-25 px-1",
      "no-body": "",
      "disabled": _vm.isCardBannerSearchDisabled
    },
    on: {
      "click": _vm.handleCardBannerSearchClick
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [!_vm.isCardBannerSearchDisabled ? _c('div', {
          staticClass: "w-100 d-flex-center gap-2 flex-nowrap"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(_vm.isVisibleBannerSearch ? 'hideBannerSearch' : 'showBannerSearch'))) + " ")]), _c('IAmIcon', {
          class: {
            'rotate-180': _vm.isVisibleBannerSearch
          },
          attrs: {
            "icon": "chevronUpOutline",
            "size": "20"
          }
        })], 1) : _c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "variant": "warning"
          }
        }), _c('span', {
          staticClass: "text-warning ml-25"
        }, [_vm._v("Đang thực hiện ...")])], 1)];
      },
      proxy: true
    }], null, false, 2633608786)
  }) : _vm._e()], 1), _c('SelectedTripCard'), _c('FastFilterByTypeSelect'), _c('ViewConfigCard', {
    attrs: {
      "is-role-f1": _vm.isRoleF1
    }
  }), _c('Filters')], 1)])], 1) : _vm._e()], 1), ['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? _c('SidebarFilter', {
    scopedSlots: _vm._u([{
      key: "fee",
      fn: function fn() {
        return [_c('div', [_c('ViewConfigCard', {
          attrs: {
            "is-role-f1": _vm.isRoleF1
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('div', [_c('Filters')], 1)];
      },
      proxy: true
    }], null, false, 1224393923),
    model: {
      value: _vm.isTaskHandlerSidebarActive,
      callback: function callback($$v) {
        _vm.isTaskHandlerSidebarActive = $$v;
      },
      expression: "isTaskHandlerSidebarActive"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }