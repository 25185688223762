<template>
  <div>
    <Search :default-search-data="searchData" />

    <SearchResultBanner
      v-show="isVisibleBannerSearch"
      @closeBanner="handleCardBannerSearchClick"
    />

    <b-card
      v-if="['xs', 'sm', 'md'].includes(appBreakPoint)"
      header-bg-variant="warning"
      header-class="p-0 rounded_header"
      class="rounded-lg mb-50 mb-lg-1 mt-50"
      no-body
    >
      <template #header>
        <b-row class="w-100 mx-0">
          <b-col
            cols="6"
            class="d-flex align-items-center text-white cursor-pointer border-right px-25 px-sm-1"
            @click="toggleVisibleCheapestHandle"
          >
            <span :class="`fw-700 mr-1 ${isMobileView ? 'font-small-4' : ''}`">
              {{ $t('flight.neighboringDays') }}
            </span>
            <div class="p-25">
              <IconSvg
                :src="require('@icons/triangle-down.svg')"
                blank-color="#ccc"
                class="py-25 rotate_class text-white"
                :style="{transform: rotateStyleCheapest, width: '18px'}"
              />
            </div>
          </b-col>
          <b-col
            cols="6"
            class="border-left d-flex align-items-center justify-content-end"
            @click="handleToggleFilter"
          >
            <span :class="`fw-700 text-white mr-1 ${isMobileView ? 'font-small-4' : ''}`">
              Bộ lọc
            </span>
            <div class="p-25">
              <IconSvg
                :src="require('@icons/filter-white.svg')"
                blank-color="#ccc"
                class="fw-600 font-medium-2"
              />
            </div>
          </b-col>
        </b-row>
      </template>

      <b-collapse v-model="isVisibleCheapestFare">
        <CheapestFare class="mb-50" />
      </b-collapse>
    </b-card>

    <SelectedTripCard v-if="['xs', 'sm', 'md'].includes(appBreakPoint)" />

    <FastFilterByTypeSelect v-if="['xs', 'sm', 'md'].includes(appBreakPoint)" />

    <b-row :class="isRoleF3 ? 'mt-1' : ''">
      <b-col
        sm="12"
        lg="8"
        xl="9"
        class="pl-50 pr-50 pr-lg-0"
      >
        <CheapestFare
          v-if="['lg', 'xl'].includes(appBreakPoint) && isVisibleCheapestFare"
          class="mb-0"
          @click="toggleVisibleCheapestHandle"
        />
        <Results :class="isVisibleCheapestFare ? '' : 'mt-50'" />
      </b-col>

      <b-col
        v-if="['lg', 'xl'].includes(appBreakPoint)"
        lg="4"
        xl="3"
        class="pr-50"
      >
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="ps-customizer-area"
        >
          <div class="right-side-sticky">
            <div class="d-flex-center gap-1">
              <b-card
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                header-bg-variant="light-info"
                :class="`flex-1 my-50 mx-25 border-info ${isCardCheapestFareDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`"
                header-class="py-25 px-25"
                no-body
                :disabled="isCardCheapestFareDisabled"
                @click="handleCardCheapestFareClick"
              >
                <template #header>
                  <div
                    v-if="!isCardCheapestFareDisabled"
                    class="w-100 d-flex align-items-center justify-content-around flex-nowrap"
                  >
                    <b-img
                      :src="require('@icons/expland-left-double.svg') "
                      blank-color="#ccc"
                      width="21"
                      :class="{ 'rotate-180': isVisibleCheapestFare }"
                    />
                    <span class="text-nowrap">{{ $t(`flight.${isVisibleCheapestFare ? 'hideCheapestFareSwiper' : 'showCheapestFareSwiper'}`) }}</span>
                    <b-img
                      class=""
                      :src="require('@icons/date-info.svg') "
                      blank-color="#ccc"
                      width="21"
                    />
                  </div>

                  <div
                    v-else
                    class="w-100 d-flex-center"
                  >
                    <b-spinner
                      small
                      variant="info"
                    />
                    <span class="text-info ml-25">Đang thực hiện ...</span>
                  </div>
                </template>
              </b-card>

              <b-card
                v-if="haveBannerSearch"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                header-bg-variant="light-warning"
                :class="`my-50 mx-25 border-warning ${isCardBannerSearchDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`"
                header-class="py-25 px-1"
                no-body
                :disabled="isCardBannerSearchDisabled"
                @click="handleCardBannerSearchClick"
              >
                <template #header>
                  <div
                    v-if="!isCardBannerSearchDisabled"
                    class="w-100 d-flex-center gap-2 flex-nowrap"
                  >
                    <span class="text-nowrap">
                      {{ $t(`flight.${isVisibleBannerSearch ? 'hideBannerSearch' : 'showBannerSearch'}`) }}
                    </span>
                    <IAmIcon
                      icon="chevronUpOutline"
                      size="20"
                      :class="{ 'rotate-180': isVisibleBannerSearch }"
                    />
                  </div>

                  <div
                    v-else
                    class="w-100 d-flex-center"
                  >
                    <b-spinner
                      small
                      variant="warning"
                    />
                    <span class="text-warning ml-25">Đang thực hiện ...</span>
                  </div>
                </template>
              </b-card>
            </div>
            <SelectedTripCard />
            <FastFilterByTypeSelect />
            <ViewConfigCard :is-role-f1="isRoleF1" />
            <Filters />
          </div>
        </vue-perfect-scrollbar>
      </b-col>
    </b-row>

    <!-- SidebarFilter -->
    <SidebarFilter
      v-if="['xs', 'sm', 'md'].includes(appBreakPoint)"
      v-model="isTaskHandlerSidebarActive"
    >
      <template #fee>
        <div>
          <ViewConfigCard :is-role-f1="isRoleF1" />
        </div>
      </template>

      <template #filter>
        <div>
          <Filters />
        </div>
      </template>
    </SidebarFilter>
  </div>
</template>

<script>
import {
  BCol, BRow, BCard, BCollapse, BImg, BSpinner,
} from 'bootstrap-vue'
import {
  ref, onBeforeMount, onUnmounted, computed, watch,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import cloneDeep from 'lodash/cloneDeep'

import store from '@/store'
import { useRouter } from '@/@core/utils/utils'

import storeModule from '@flightv2/bookingStoreModule'
import useBookingHandle from '@flightv2/useBookingHandle'

import useToast from '@useToast'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCollapse,
    BImg,
    BSpinner,

    VuePerfectScrollbar,
    Filters: () => import('./filters.vue'),
    Search: () => import('@flightv2/search/re-search/index.vue'),
    CheapestFare: () => import('./components/Cheapest-fare.vue'),
    Results: () => import('./results.vue'),
    SidebarFilter: () => import('./SidebarFilter.vue'),
    ViewConfigCard: () => import('./components/ViewConfigCard.vue'),
    SelectedTripCard: () => import('./components/SelectedTripCard.vue'),
    FastFilterByTypeSelect: () => import('./components/FastFilterByTypeSelect.vue'),
    SearchResultBanner: () => import('./SearchResultBanner.vue'),
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      fetchAirportGroup, FLIGHT_APP_STORE_MODULE_NAME, searchFlightv2,
    } = useBookingHandle()
    if (!store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
      store.registerModule(FLIGHT_APP_STORE_MODULE_NAME, storeModule)
    }
    onUnmounted(() => {
      if (store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(FLIGHT_APP_STORE_MODULE_NAME)
      }
    })
    if (isEmpty(store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getAirportGroup`])) { fetchAirportGroup() }
    const { toastError } = useToast()
    const { router } = useRouter()
    const searchData = ref(null)

    function redirectToSearch() {
      toastError('Lỗi tìm kiếm, vui lòng thử lại...')
      router.replace({ name: 'apps-flightsV2-page' })
    }

    function handleUpdateDataSearch() {
      const storageData = localStorage.getItem('searchFlight')
      if (storageData) {
        const storageDataObject = JSON.parse(storageData)
        if (!storageDataObject?.flights
        || !storageDataObject?.type
        || !storageDataObject?.adult
        || storageDataObject?.child === undefined
        || storageDataObject?.infant === undefined
        ) {
          redirectToSearch()
          return
        }
        delete storageDataObject.sources
        searchData.value = storageDataObject

        if (isEmpty(searchData.value?.airlines) && !isEmpty(searchData.value?.sources)) {
          searchData.value.airlines = searchData.value.sources
        }
        // delete searchData.airline)
      } else {
        redirectToSearch()
      }
    }

    if (!searchData.value) handleUpdateDataSearch()

    onBeforeMount(() => {
      const payload = cloneDeep(searchData.value) // NOTE: tránh fn searchFlightv2 modify data
      searchFlightv2(payload)
    })

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    // ANCHOR - Sidebar - Filter
    const isTaskHandlerSidebarActive = ref(false)

    function handleToggleFilter() {
      isTaskHandlerSidebarActive.value = !isTaskHandlerSidebarActive.value
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: true,
    }

    const getExpiredAtToReloadRequire = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getExpiredAtToReloadRequire`])

    const timeoutId = ref()

    function clearTimeoutExpired() {
      if (timeoutId.value) {
        clearTimeout(timeoutId.value)
        timeoutId.value = null
      }
    }

    watch(getExpiredAtToReloadRequire, () => {
      if (getExpiredAtToReloadRequire.value && !timeoutId.value) {
        const now = (new Date()).getTime()
        clearTimeoutExpired()
        timeoutId.value = setTimeout(() => {
          Vue.swal({
            title: 'Cảnh báo!',
            html: `
                  <div class="text-20px font-weight-bolder">
                    Hết thời gian tìm kiếm chuyến bay.
                    Vui lòng tìm kiếm lại chuyến bay.
                  </div>`,
            icon: 'warning',
            iconColor: '#EF5350',
            showCancelButton: false,
            showConfirmButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: 'Tìm kiếm lại',
            customClass: {
              confirmButton: 'btn btn-outline-danger',
              title: 'text-20px font-weight-bolder text-danger',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              window.location.reload()
            }
          })
        }, (getExpiredAtToReloadRequire.value - now > 0 ? (getExpiredAtToReloadRequire.value - now) : 15 * 60 * 1000))
      }
    }, { immediate: true })
    onUnmounted(() => {
      clearTimeoutExpired()
    })

    const isVisibleCheapestFare = ref(true)
    const rotateStyleCheapest = computed(() => isVisibleCheapestFare.value ? 'rotate(0deg)' : 'rotate(-180deg)')

    const isCardCheapestFareDisabled = ref(false)

    const toggleVisibleCheapestHandle = () => {
      isVisibleCheapestFare.value = isVisibleCheapestFare.value !== true
      localStorage.setItem('visibleCheapestFare', isVisibleCheapestFare.value)
    }

    const handleCardCheapestFareClick = () => {
      if (!isCardCheapestFareDisabled.value) {
        isCardCheapestFareDisabled.value = true
        toggleVisibleCheapestHandle()

        setTimeout(() => {
          isCardCheapestFareDisabled.value = false
        }, 300)
      }
    }

    const isVisibleBannerSearch = ref(true)
    const isCardBannerSearchDisabled = ref(false)

    const toggleVisibleBannerSearch = () => {
      isVisibleBannerSearch.value = isVisibleBannerSearch.value !== true
      localStorage.setItem('visibleBannerSearch', isVisibleBannerSearch.value)
    }

    const handleCardBannerSearchClick = () => {
      if (!isCardBannerSearchDisabled.value) {
        isCardBannerSearchDisabled.value = true
        toggleVisibleBannerSearch()

        setTimeout(() => {
          isCardBannerSearchDisabled.value = false
        }, 300)
      }
    }
    const haveBannerSearch = computed(() => store.getters['app/getBannerSearch']?.length && !isRoleF3.value)
    return {
      searchData,
      isRoleF3,
      isRoleF1,
      perfectScrollbarSettings,
      isTaskHandlerSidebarActive,
      handleToggleFilter,
      isVisibleCheapestFare,
      rotateStyleCheapest,
      toggleVisibleCheapestHandle,
      handleCardCheapestFareClick,
      isCardCheapestFareDisabled,
      isVisibleBannerSearch,
      handleCardBannerSearchClick,
      isCardBannerSearchDisabled,
      haveBannerSearch,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
// .right-side-sticky {
//   position: sticky;
//   top: 50px;
// }

.ps-customizer-area {
  position: sticky;
  top: 50px;
  max-height: calc(100vh - 50px);
}

.rotate_class {
  transition: transform 0.3s ease;
}
</style>
